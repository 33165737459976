import { useEffect, useState } from "react";
import { useGetResponsiblesFilterMutation } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { useSelector } from "react-redux";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";

export function useResponsiblesFilter() {
  const [data, setData] = useState([]);
  const [metadata, setMetaData] = useState([]);
  const [clasification, setClasification] = useState("");
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const [getResponsibles] = useGetResponsiblesFilterMutation();



  useEffect(() => {
    const handleGetResponsibles = async () => {
      const filters = {
        filtersJson: JSON.stringify([
          { "TARCYYYY.ESTAIDXX": { NOTIN: [101, 103] } },
          { "TARIYYYY.LIMSUPFX": { IN: ["000-00-00"] } },
          { "TARIYYYY.LIMSUPHX": { IN: ["000-00-00"] } },
        ]),
      }
      const { data: clasifications } = await getResponsibles({ CARIDXXX, USRIDXXX, CLATARID: clasification, filters });
      if (clasifications) {
        setMetaData(clasifications?.meta?.focused);
        setData(clasifications?.data);
      }
    };
    handleGetResponsibles();
  }, [CARIDXXX, USRIDXXX, clasification, getResponsibles]);

  return [data, metadata, setClasification];
}
