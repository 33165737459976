import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: "",
    token: null,
    infoUser: null,
    menuUser: null,
    isLoggedUserPass: false,
    isLoggedIn: false,
    isChangePass: false,
    initialMenuUser: null,
    isReplaceUser: false,
    userReplace: "",
    showUserReplace: false,
    showSuccessMessage: false,
    passwordFormMessage: "",
    moduleURL: "usuarios",
    readNotifications: {},
    tokenGenio: null,
    infoUserReplace: {},
    temporalUsers: [],
    listTemporalUsers: [],
  },
  reducers: {
    signIn: (state, action) => {
      const { USRIDXXX } = action.payload.userData.data;
      state.user = USRIDXXX.toString();
      state.isLoggedUserPass = true;
    },
    signToken: (state, action) => {
      const { token } = action.payload.userData.data[0];
      const { INFOUSER } = action.payload.userData.data[0];
      const { USRIDXXX } = action.payload.userData.data[0].INFOUSER;
      state.user = USRIDXXX.toString();
      state.infoUser = INFOUSER;
      state.menuUser = INFOUSER.USERPERM;
      state.token = token;
      state.isLoggedIn = true;
      state.initialMenuUser = INFOUSER.USERPERM;
      state.showUserReplace = INFOUSER.REPLACEX;
    },
    signOut: (state) => {
      state.user = null;
      state.token = null;
      state.infoUser = null;
      state.menuUser = null;
      state.isLoggedUserPass = false;
      state.isLoggedIn = false;
      state.isChangePass = false;
      state.initialMenuUser = null;
      state.passwordFormMessage = "";
      state.moduleURL = "usuarios";
      state.infoUserReplace = {};
      state.isReplaceUser = false;
    },
    changePass: (state, action) => {
      const { USRIDXXX, message } = action.payload.error.data.errors;
      state.user = USRIDXXX.toString();
      state.isChangePass = true;
      state.passwordFormMessage = message;
    },
    setMenuUser: (state, action) => {
      state.menuUser = action.payload;
    },
    setMenuUserReplace: (state, action) => {
      state.menuUser = action.payload;
      state.infoUser = { ...state.infoUser, USERPERM: action.payload };
    },
    setMenuInitialUser: (state) => {
      state.menuUser = state.initialMenuUser;
      state.infoUser = { ...state.infoUser, USERPERM: state.initialMenuUser };
    },
    setIsReplaceUser: (state, action) => {
      state.isReplaceUser = action.payload;
    },
    setUserReplace: (state, action) => {
      state.userReplace = action.payload;
    },
    showSuccessMessage: (state, action) => {
      state.showSuccessMessage = action.payload;
    },
    setModuleURL: (state, action) => {
      state.moduleURL = action.payload;
    },
    setReadNotifications: (state, action) => {
      const { id, read } = action.payload;
      state.readNotifications = { ...state.readNotifications, [id]: !read };
    },
    setReadMasiveNotifications: (state, action) => {
      state.readNotifications = action.payload;
    },
    resetNotifications: (state) => {
      state.readNotifications = {};
    },
    setTokenGenio: (state, action) => {
      state.tokenGenio = action.payload;
    },
    setInfoUserReplace: (state, action) => {
      state.infoUserReplace = action.payload;
    },
    setTemporalUsers: (state, action) => {
      state.temporalUsers = action.payload;
      if (Object.entries(state.infoUserReplace).length === 0) {
        state.listTemporalUsers = action.payload;
      }
    },
    setListTemporalUsers: (state) => {
      let users = state.temporalUsers;
      if (Object.entries(state.infoUserReplace).length > 0) {
        //Valido que no exista la cedula del usuario actual en el listado
        const { USRIDXXX, USREMAXX, USRNOMXX } = state.infoUser;
        const exists = state.temporalUsers.filter((user) => user === USRIDXXX);
        if (exists.length === 0) {
          users = [{ USRIDTXX: USRIDXXX, USREMAXX, USRNOMXX, ORIGINAL: true }, ...users];
        }
        users = users.filter((item) => item.USRIDTXX !== state.infoUserReplace.USRIDTXX);
      }
      state.listTemporalUsers = users;
    },
  },
});

export const {
  signIn,
  signToken,
  signOut,
  changePass,
  setMenuUser,
  setMenuUserReplace,
  setMenuInitialUser,
  setIsReplaceUser,
  setUserReplace,
  showSuccessMessage,
  setModuleURL,
  setReadNotifications,
  setReadMasiveNotifications,
  resetNotifications,
  setTokenGenio,
  setInfoUserReplace,
  setTemporalUsers,
  setListTemporalUsers,
} = authSlice.actions;
export default authSlice.reducer;

export const getisLoggedIn = (state) => {
  return state.persistedReducer.isLoggedIn;
};
export const getisLoggedUserPassword = (state) => {
  return state.persistedReducer.isLoggedUserPass;
};

export const getisChangePass = (state) => {
  return state.persistedReducer.isChangePass;
};

export const getUser = (state) => {
  return state.persistedReducer.user;
};

export const getDataUser = (state) => {
  return state.persistedReducer.infoUser;
};

export const getMenuUser = (state) => {
  return state.persistedReducer.menuUser;
};

export const getInitialMenuUser = (state) => {
  return state.persistedReducer.initialMenuUser;
};

export const getIsReplaceUser = (state) => {
  return state.persistedReducer.isReplaceUser;
};

export const getUserReplace = (state) => {
  return state.persistedReducer.userReplace;
};

export const getTokenUser = (state) => {
  return state.persistedReducer.token;
};

export const getShowUserReplace = (state) => {
  return state.persistedReducer.showUserReplace;
};

export const getShowSuccessMessage = (state) => {
  return state.persistedReducer.showSuccessMessage;
};

export const getPasswordFormMessage = (state) => {
  return state.persistedReducer.passwordFormMessage;
};

export const getReadNotifications = (state) => {
  return state.persistedReducer.readNotifications;
};

export const getTokenGenio = (state) => {
  return state.persistedReducer.tokenGenio;
};

export const getInfoUserReplace = (state) => {
  return state.persistedReducer.infoUserReplace;
};

export const getListTemporalUsers = (state) => {
  return state.persistedReducer.listTemporalUsers;
};
