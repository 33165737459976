/**
 * Slice para el maneja del estado para el componente Saldos Documento de Transporte
 */
import { createSlice } from "@reduxjs/toolkit";

//Estado inicial de cada pestaña
const statusShowView = {
  general: true,
  eta: false,
  traffic: false,
  expiration: false,
  release: false,
};

const initialState = {
  statusShowView,
};

const saldoctrSlice = createSlice({
  name: "saldoctr",
  initialState: initialState,
  reducers: {
    setStatusShowView: (state, action) => {
      state.statusShowView = action.payload;
    },
  },
});

export const { setStatusShowView } = saldoctrSlice.actions;

export default saldoctrSlice.reducer;

export const gatAllState = (state) => {
  return state.saldoctr;
};
