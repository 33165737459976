import { useSelector } from "react-redux";
import { useGetDataGraphMutation } from "../../../libs/redux/slices/Graficsx/graficsxApiSlice";
import { getDataUser } from "../../../libs/redux/slices/authSlice/authSlice";
import { useEffect, useState } from "react";

export function useDataGraph({ MODIDXXX, PROIDXXX }) {
  const [filtersJson, setFiltersJson] = useState([]);
  const [dataGraph, setDataGraph] = useState();
  const { USRIDXXX, CARIDXXX } = useSelector(getDataUser);
  const [getDataGraph] = useGetDataGraphMutation();

  const handleResetFilterJson = () => {
    setFiltersJson({
      filtersJson: JSON.stringify([
        { "TARCYYYY.ESTAIDXX": { NOTIN: [101, 103] } },
        { "TARIYYYY.LIMSUPFX": { IN: ["000-00-00"] } },
        { "TARIYYYY.LIMSUPHX": { IN: ["000-00-00"] } },
      ]),
    });
  };

  const handleSetFilterJson = (newFilters) => {
    setFiltersJson(newFilters);
  };

  useEffect(() => {
    const handleGetData = async () => {
      const { data } = await getDataGraph({ filters: filtersJson, USRIDXXX, CARIDXXX });
      if (data) {
        setDataGraph(data);
      }
    };
    handleGetData();
  }, [CARIDXXX, USRIDXXX, filtersJson, getDataGraph]);

  return { dataGraph, handleSetFilterJson, handleResetFilterJson };
}
