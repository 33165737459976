/*
 * Componente hijo del dashboard: Renderiza el componente hijo del dashboard
 * Muestra el componente que corresponde al modulo y proceso seleccionado
 */
import { TableLoader } from "codekit";

import { lazy, Suspense, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { getProcesSelected, getModuleSelected } from "../libs/redux/slices/MenuSlice/menuSlice";
import { resetExport } from "../libs/redux/slices/exportExcSlice/exportExcSlice";
import {
  resetCustomFilters,
  resetFilters,
  getRestoreFilts,
  setCheckedRows,
  setIscheckedAll,
} from "../libs/redux/slices/trackingSlice/trackingSlice";
import { resetConsult } from "../libs/redux/slices/consultSlice/consultSlice";

import DashboardHome from "./DashboardHome";

const DashboardChildRoute = () => {
  const { MODURLXX } = useSelector(getModuleSelected);
  const { PRODURLX } = useSelector(getProcesSelected);
  const restoretkr = useSelector(getRestoreFilts);
  const dispatch = useDispatch();

  const canRender = MODURLXX && PRODURLX;

  useEffect(() => {
    //limpio datos de la consulta y exportar
    dispatch(resetExport());
    dispatch(resetFilters());
    dispatch(resetConsult());
    dispatch(resetCustomFilters());
    dispatch(setCheckedRows([]))
    dispatch(setIscheckedAll(false));
  }, [dispatch, MODURLXX, PRODURLX, restoretkr]);

  //Se previene el evento del submit para todos los formularios
  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      e.key === "Enter" && e.preventDefault();
    });
  }, []);

  if (canRender) {
    let CurrentChildComponent = lazy(() => import(`../components/${MODURLXX}/${PRODURLX}`));
    return <Suspense fallback={<TableLoader />}>{canRender && <CurrentChildComponent />}</Suspense>;
  }
  return <DashboardHome />;
};

export default DashboardChildRoute;
