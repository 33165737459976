import { createSlice } from '@reduxjs/toolkit';

const defaultFilters = {
  page: 1,
  perPage: 12//process.env.REACT_APP_ROWS_PER_PAGE_TRACKING
};

const tracscrlSlice = createSlice({
  name: "tracscrl",
  initialState: {
    data: {},
    columns: [],
    colsTracking: [],
    defaultFilters: defaultFilters,
    totalData: 0,
    filters: [],
  },
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setColumns: (state, action) => {
      state.columns = action.payload;
    },
    setColsTracking: (state, action) => {
      state.colsTracking = action.payload;
    },
    setDefaultFilters: (state, action) => {
      state.defaultFilters = action.payload;
    },
    setSortTracking: (state, action) => {
      state.defaultFilters = { ...state.defaultFilters, sorts: action.payload };
    },
    setTotalData: (state, action) => {
      state.totalData = action.payload;
    },
    cleanDataTracking: (state) => {
      state.dataTracking = {};
    },
    setFiltersTracking: (state, action) => {
      state.filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = {};
    }
  },
});
export const {
  setData,
  setColumns,
  setColsTracking,
  setDefaultFilters,
  setTotalData,
  cleanDataTracking,
  setFiltersTracking
} = tracscrlSlice.actions;
export default tracscrlSlice.reducer;

//Export Data
export const getData = (state) => {
  return state.tracscrl.data;
};
//Export Columns
export const getColumns = (state) => {
  return state.tracscrl.columns;
};
//Columnas del tracking
export const getColsTracking = (state) => {
  return state.tracscrl.colsTracking;
};
//Filtros personalizados
export const getDefaultFilters = (state) => {
  return state.tracscrl.defaultFilters;
};
//Total Data
export const getTotalData = (state) => {
  return state.tracscrl.totalData;
};
//Filtros del tracking
export const getFiltersTracking = (state) => {
  return state.tracscrl.filters;
};
