/**
 * Componente de formulario de login: Permite iniciar sesion en la plataforma
 */

import {
  Body,
  Button,
  Description,
  Heading,
  Input,
  Label,
  Spreen,
  SpreenBody,
  SpreenFooter,
  SpreenLeft,
  SpreenRight,
  Stack,
  StackRow,
  Title,
} from "codekit";

import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { useLoginMutation } from "../../libs/redux/slices/authSlice/authApiSlice";
import { changePass, signIn, signToken, setTokenGenio } from "../../libs/redux/slices/authSlice/authSlice";
import { reset } from "../../libs/redux/slices/MenuSlice/menuSlice";
import { useLoginGenioMutation } from "../../libs/redux/slices/Genioxxx/genioxxxApiSlice";

const Lognform = () => {
  const dispatch = useDispatch();

  const [login, { isLoading }] = useLoginMutation();
  const [logGenio] = useLoginGenioMutation();

  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (formData) => {
    const { name } = formData;

    try {
      const userData = await login(formData).unwrap();
      const shouldValidate = userData?.data?.USRIDXXX;
      const hasTokenAccess = userData?.data[0]?.token;

      if (shouldValidate) {
        dispatch(signIn({ name, userData }));
      } else if (hasTokenAccess) {
        const { USRIDXXX } = userData?.data[0]?.INFOUSER;
        dispatch(signToken({ USRIDXXX, userData }));
        dispatch(reset());
        const { data } = await logGenio({ "USRIDXXX": USRIDXXX });
        if (data) {
          dispatch(setTokenGenio(data.data[0].token))
        }
      }
    } catch (error) {
      const errorStatus = error.status;

      if (errorStatus === 422) {
        const errorChangePassword = error.data?.errors?.changePassword;

        if (errorChangePassword) {
          dispatch(changePass({ name: formData.name, error }));
        }
      }
    }
  };

  return (
    <Spreen variant="login" srcBgImage="/images/login-page.jpg">
      <SpreenLeft>
        <SpreenBody variant="login">
          <Heading variant="heading-6" alignment="center">
            Login
          </Heading>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack gap={8}>
              <StackRow>
                <Label htmlFor="name">Correo electronico ó cedula</Label>
                <Input
                  id="name"
                  type="text"
                  disabled={isLoading}
                  {...register("name", { required: true })}
                  isInvalid={errors.name}
                  placeholder="P. eje. grupo.repremundo@repr..."
                  style={{ textTransform: "none" }}
                />
              </StackRow>
              <StackRow>
                <Label htmlFor="password">Password</Label>
                <Input
                  id="password"
                  type="password"
                  disabled={isLoading}
                  {...register("password", { required: true })}
                  isInvalid={errors.password}
                  placeholder="Ingresa tu contraseña"
                  style={{ textTransform: "none" }}
                />
              </StackRow>
              <Button type="submit" variant="primary-bold" disabled={!isValid} isLoading={isLoading}>
                Ingresar
              </Button>
            </Stack>
          </form>
        </SpreenBody>
        <SpreenFooter>
          <Link to={"/recover-password"}>
            <a href="/recover-password" className="link-inline">
              <Title asSpan variant="title-2" color="secondary-600">
                ¿Olvidaste tu contraseña?
              </Title>
            </a>
          </Link>
        </SpreenFooter>
      </SpreenLeft>
      <SpreenRight>
        <Stack gap={4}>
          <Body variant="body-2">15 de Junio, 2023</Body>
          <Heading variant="heading-6">Ministerio modifica la resolución 0479 de 2023.</Heading>
          <Body variant="body-3">
            Mediante la Resolución 0479 de 2023, el Ministerio de Comercio, Industria y Turismo; modifica parcialmente
            la Resolución 0480 de 2020, y se actualiza su Anexo 1; esta modificación incorpora lineamientos para la
            información que deben reportar, para el registro de productores y comercializadores de aparatos eléctricos y
            electrónicos - RPCAEE a través de la Ventanilla Única de Comercio Exterior VUCE.
          </Body>
          <div className="rounded border border-transparent p-[1rem] text-center grid gap-[1rem]">
            <Title variant="title-2">¿Necesitas ayuda?</Title>
            <Description variant="description-2">Haz click en el siguiente botón para crear un ticket.</Description>
            <a
              href="https://conexiongrm.repremundo.com.co/produccion/Helpdesk/"
              rel="noreferrer"
              target="_blank"
              className="link-full"
            >
              <Button asSpan variant="secondary-bold">
                Crear un ticket
              </Button>
            </a>
          </div>
        </Stack>
      </SpreenRight>
    </Spreen>
  );
};

export default Lognform;
