/**
 * Componente para el pintado de las notificaciones de un usuario
 * @author Oscar Rosero <oscar.rosero@repremundo.com.co>
 */

import { Description, HeaderNotificationItem, HeaderNotifications, BoxStatus } from "codekit";

import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useLazyGetNotificationUserQuery,
  useGetPollingNotificationUserQuery,
  useMarkAsReadMasiveMutation,
  useMarkAsReadMutation,
  useGetPollingGeneralNotificationUserQuery,
  useLazyGetNotificationsReadQuery,
} from "../libs/redux/slices/NotificxSlice/notificxApliSlice";
import { getPage } from "../libs/redux/slices/NotificxSlice/notificxSlice";

import InfiniteScroll from "react-infinite-scroll-component";
import { getReadNotifications, setReadMasiveNotifications, setReadNotifications } from "../libs/redux/slices/authSlice/authSlice";

export const Notificx = () => {
  const dispatch = useDispatch();
  const page = useSelector(getPage);
  const { data: countData, isSuccess } = useGetPollingNotificationUserQuery(
    { page, filters: { NOTCLAXX: "URGENTE" } },
    {
      pollingInterval: process.env.REACT_APP_POLLING_INTERVAL,
    }
  );
  const [getNotificationsRead] = useLazyGetNotificationsReadQuery();
  //Notificaciones generales
  const { data: dataGeneral, isSuccess: isSuccessGeneral } = useGetPollingGeneralNotificationUserQuery(
    { page, filters: { NOTCLAXX: "" } },
    {
      pollingInterval: process.env.REACT_APP_POLLING_INTERVAL,
    }
  );
  const [quantity, setQuantity] = useState(0);
  const [actualView, setActualView] = useState("URGENTE");
  const readNotifications = useSelector(getReadNotifications);
  const [finalQuantity, setFinalQuantity] = useState(0);
  const [markAsRead] = useMarkAsReadMutation();
  const [markAsReadMasive] = useMarkAsReadMasiveMutation();
  const [getNotificationUser] = useLazyGetNotificationUserQuery();
  const [urgentNotification, setUrgentNotification] = useState([]);
  const [generalNotification, setGeneralNotification] = useState([]);
  const [metaUrgent, setMetaUrgent] = useState({});
  const [metaGeneral, setMetaGeneral] = useState({});

  //Hook para setear la informacion de las notificaciones urgente
  useEffect(() => {
    if (isSuccess) {
      const { data, meta } = countData;
      setMetaUrgent(meta);
      if (meta.current_page > 1) {
        const prevData = [...urgentNotification];
        const newData = prevData.concat(data).filter((value, index, array) => array.indexOf(value) === index);
        setUrgentNotification(newData);
      } else {
        setUrgentNotification(data ?? []);
      }
    }
    // eslint-disable-next-line
  }, [isSuccess, countData]);

  //Hook para setear la informacion de las notificaciones Generales
  useEffect(() => {
    if (isSuccessGeneral) {
      const { data, meta } = dataGeneral;
      setMetaGeneral(meta);
      if (meta.current_page > 1) {
        const prevData = [...generalNotification];
        const newData = prevData.concat(data).filter((value, index, array) => array.indexOf(value) === index);
        setGeneralNotification(newData ?? []);
      } else {
        setGeneralNotification(data ?? []);
      }
    }
    // eslint-disable-next-line
  }, [isSuccessGeneral, dataGeneral]);

  //Consultar data desde el scroll infinito
  const handleChangePage = async (page) => {
    await getNotificationUser({ page, filters: { NOTCLAXX: actualView } })
      .unwrap()
      .then((response) => {
        const { data, meta } = response;
        if (actualView === "URGENTE") {
          const prevData = [...urgentNotification];
          const newData = prevData.concat(data).filter((value, index, array) => array.indexOf(value) === index);
          setMetaUrgent(meta);
          setUrgentNotification(newData);
        } else {
          const prevData = [...generalNotification];
          const newData = prevData.concat(data).filter((value, index, array) => array.indexOf(value) === index);
          setMetaGeneral(meta);
          setGeneralNotification(newData);
        }
      })
      .catch();
  };

   //Hook para setear las notificaciones leidas
   useEffect(() => {
    const handleNotificationsRead = async () => {
      await getNotificationsRead()
        .unwrap()
        .then((response) => {
          const { data } = response;
          let read = {};
          data.forEach((element) => {
            read = { ...read, [element.NOTIDXXX]: true };
          });
          dispatch(setReadMasiveNotifications(read));
        })
        .catch();
    };
    handleNotificationsRead();
  }, [getNotificationsRead, dispatch]);

  useEffect(() => {
    //Obtengo la cantidad de notificaciones leidas
    const numberRead = Object.values(readNotifications).filter((value) => value).length;
    const total = metaUrgent.total + metaGeneral.total;
    setQuantity(total);
    setFinalQuantity(total - numberRead);
  }, [readNotifications, metaUrgent, metaGeneral]);

  const handleMarkAsRead = async (NOTIDXXX, isRead) => {
    dispatch(setReadNotifications({ id: NOTIDXXX, read: readNotifications[NOTIDXXX] }));
    await markAsRead({ NOTIDXXX, NOTLEIXX: isRead ? "NO" : "SI" }).unwrap();
  };

  //Funcion para marcar todas las notificaciones como leidas
  const handleMarkAsReadMasive = async () => {
    let read = {};
    let dataNotification = {};
    if (actualView === "URGENTE") {
      dataNotification = urgentNotification
    } else {
      dataNotification = generalNotification;
    }
    const dataFilter = dataNotification.filter((element) => element.NOTCLAXX === actualView);
    dataFilter.forEach((element) => {
      read = { ...read, [element.NOTIDXXX]: true };
    });
    dispatch(setReadMasiveNotifications(read));
    await markAsReadMasive({ NOTLEIXX: "SI", NOTCLAXX: actualView }).unwrap();
  };

  return (
    <HeaderNotifications
      quantity={quantity}
      unRead={finalQuantity}
      section={actualView}
      onSetSection={setActualView}
      onMarkMasive={handleMarkAsReadMasive}
    >
      <>
        {actualView === "URGENTE" && (
          <InfiniteScroll
            dataLength={urgentNotification.length}
            hasMore={metaUrgent.current_page < metaUrgent.last_page}
            next={() => handleChangePage(page + 1)}
            scrollableTarget="scrollableDiv"
            loader={<BoxStatus variant="loading" text="Cargando información..." />}
          >
            <>
              {urgentNotification.map((item, index) => (
                <Fragment key={`notiifcacion-${index}`}>
                  <HeaderNotificationItem
                    onMarkAsRead={() => {
                      handleMarkAsRead(item.NOTIDXXX, readNotifications[item.NOTIDXXX]);
                    }}
                    bg={`${readNotifications[item.NOTIDXXX] ? "neutral-300" : "primary-100"}`}
                    mb={2}
                    isRead={readNotifications[item.NOTIDXXX]}
                    section={actualView}
                  >
                    <Description variant="description-2">{item.NOTDESXX}</Description>
                    <Description variant="description-3" color="primary-700">
                      {`${item.REGFECXX} ${item.REGHORXX}`}
                    </Description>
                  </HeaderNotificationItem>
                </Fragment>
              ))}
            </>
          </InfiniteScroll>
        )}
        {actualView === "" && (
          <InfiniteScroll
            dataLength={generalNotification.length}
            hasMore={metaGeneral.current_page < metaGeneral.last_page}
            next={() => handleChangePage(page + 1)}
            scrollableTarget="scrollableDiv"
            loader={<BoxStatus variant="loading" text="Cargando información..." />}
          >
            <>
              {generalNotification.map((item, index) => (
                <Fragment key={`notiifcacion-general-${index}`}>
                  <HeaderNotificationItem
                    onMarkAsRead={() => {
                      handleMarkAsRead(item.NOTIDXXX, readNotifications[item.NOTIDXXX]);
                    }}
                    bg={`${readNotifications[item.NOTIDXXX] ? "neutral-200" : "white"}`}
                    mb={2}
                    isRead={readNotifications[item.NOTIDXXX]}
                    section={actualView}
                  >
                    <Description variant="description-2">{item.NOTDESXX}</Description>
                    <Description variant="description-3" color="primary-700">
                      {`${item.REGFECXX} ${item.REGHORXX}`}
                    </Description>
                  </HeaderNotificationItem>
                </Fragment>
              ))}
            </>
          </InfiniteScroll>
        )}
      </>
    </HeaderNotifications>
  );
};
